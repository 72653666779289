"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
	//header添加token
	// let token = window.sessionStorage.getItem('token') || ''
	// let token = Vue.prototype.VueCookies.get("token") || ''
	// if(token){
	// 	config.headers['token'] = token
	// }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if(response.data.status){
		if(response.data.status){
			return response.data;
		}else{
			let data = response.data
			if(!data.status){
				Message.error(data.msg)
			}else{
				Message.error('出错了~')
			}
		}
	}else{
		return response.data;
	}
  },
  function(error) {
    // Do something with response error
	// 全局错误提示
	if(error.response && error.response.data && error.response.data.errorCode){
		Message.error(error.response.data.msg)
	}
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
