import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import * as echarts from 'echarts'
Vue.use(echarts);
Vue.prototype.$echarts = echarts;

import 'echarts-gl' // 3d图表库

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { on } from 'element-ui/src/utils/dom'
Vue.use(ElementUI);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.prototype.VueCookies = VueCookies;

// import 'lib-flexible';

// 设定body文字尺寸
const setHtmlFontSize = () => {
// 	// 640 默认设计稿大小; 640px = 6.4rem ;每个元素px基础上/100
// 	const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
// 	// 得到html的Dom元素
// 	const htmlDom = document.getElementsByTagName('html')[0];
// 	// 设置根元素字体大小
// 	htmlDom.style.fontSize = htmlWidth / 19.2 + 'px';

    const baseSize = 100 // 基准值
    // 相对于1920像素的缩放比 
    let scale = document.documentElement.clientWidth / 1920
    // 根据屏幕变化 1rem 对应的 font-size
    scale = scale > 1 ? 1 : scale;
    const realFont = baseSize * scale
    document.documentElement.style.fontSize = realFont + 'px'
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
