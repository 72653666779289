import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决重复触发了同一个路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index.vue'),
	meta: {
	  title: '主页'
	},
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
